/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { useLogin } from '../../../../pages/user/login/hooks/useLogin';
import SnackbarMessage from '../../../../pages/user/login/components/SnackbarMessage/SnackbarMessage';
import { Link } from 'react-router-dom';

const LoginContent = () => {
  const { t } = useTranslation('login');

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    email,
    setEmail,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    emailError,
    passwordError,
    isSubmitting,
    snackbarMessage,
    handleLogin,
  } = useLogin();

  useEffect(() => {
    if (snackbarMessage) {
      setSnackbarOpen(true);
    }
  }, [snackbarMessage]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Empêche le rechargement de la page
    handleLogin(); // Appelle la fonction de connexion
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack width={'100%'} mt={8} spacing={4}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack gap={3}>
          <TextField
            id="email"
            label={t('LABEL_EMAIL_ADDRESS')}
            variant="outlined"
            placeholder={t('PLACEHOLDER_EMAIL_ADDRESS')}
            type="email"
            fullWidth
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(emailError)}
            helperText={emailError || ''}
          />

          <TextField
            id="password"
            label={t('LABEL_PASSWORD')}
            placeholder={t('PLACEHOLDER_PASSWORD')}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={Boolean(passwordError)}
            helperText={passwordError || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mt={0.8}
          mb={4}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              }
              label={t('CHECKBOX_REMEMBER_ME')}
            />
          </FormGroup>
          <Typography
            variant="body1"
            component={Link}
            to={'/forgot-password'}
            sx={{
              textDecoration: 'underline',
              fontStyle: 'italic',
              color: 'inherit',
            }}
          >
            {t('LINK_FORGOT_PASSWORD')}
          </Typography>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('BUTTON_LOG_IN')}
        </Button>
      </form>
      <SnackbarMessage
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </Stack>
  );
};

export default LoginContent;
