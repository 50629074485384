/** @jsxImportSource @emotion/react */
import React from 'react';

import ButtonToggle from '../../components/ButtonToggle/ButtonToggle';
import LoginContent from '../../Contents/LoginContent/LoginContent';

import { Box, Card, Stack } from '@mui/material';

import { cardStyles, cardWrapperStyles } from './styles';

import LogoRMF from '../../../../assets/images/fullLogo.png';

const Login = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          height: '400px',
          width: '400px',
          position: 'absolute',
          bottom: '-150px',
          right: '-150px',
          backgroundColor: '#A855F7',
          filter: 'blur(300px)',
          opacity: 0.5,
        }}
      />
      <Box
        sx={{
          height: '400px',
          width: '400px',
          position: 'absolute',
          top: '-150px',
          left: '-150px',
          backgroundColor: '#3B82F6',
          filter: 'blur(300px)',
          opacity: 0.5,
        }}
      />
      <Stack css={cardWrapperStyles}>
        <Card elevation={0} css={cardStyles}>
          <Box
            component={'img'}
            src={LogoRMF}
            width={'auto'}
            height={'100px'}
            mb={5}
            sx={{
              display: 'block',
            }}
          />
          <ButtonToggle />
          <LoginContent />
        </Card>
      </Stack>
    </Box>
  );
};

export default Login;
