/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { css } from '@emotion/react';
import {
  textFieldStyles,
  iconStyles,
  buttonStyles,
  namesContainerStyles,
} from './styles';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../../services/post/register/register'; // Votre service
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { MuiTelInput } from 'mui-tel-input';
import { useAuth } from '../../../../context/AuthContext/AuthContext';

interface CountryItem {
  countryCode: string;
  commonName: string;
}

const RegisterContent = () => {
  const theme = useTheme();
  const { t } = useTranslation('register');
  const { getCode } = require('country-list');
  const navigate = useNavigate();

  // Récupération si besoin d'un éventuel user déjà connecté
  const { user } = useAuth();
  const { login } = useAuth();

  const [countriesList, setCountriesList] = useState<CountryItem[]>([]);
  const [defaultCountry, setDefaultCountry] = useState('');

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
    password: '',
    country: '',
    referredBy: localStorage.getItem('affiliate') || Cookies.get('affiliate'),
  });

  const [fieldErrors, setFieldErrors] = useState<{
    firstname?: string;
    lastname?: string;
    email?: string;
    phoneNumber?: string;
    password?: string;
    country?: string;
    acceptTnc?: string;
    newsletter?: string;
  }>({});

  const [acceptTnc, setAcceptTnc] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  // Affichage / masquage du mot de passe
  const [showPassword, setShowPassword] = useState(false);

  // Focus sur le champ "password" => condition d'affichage des pastilles
  const [passwordFocused, setPasswordFocused] = useState(false);

  // Contrôle téléphone
  const [phoneError, setPhoneError] = useState('');
  const [phoneIsValid, setPhoneIsValid] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Redirection si user déjà loggé
  useEffect(() => {
    if (user) {
      if (user.role === 'ROLE_ADMIN') {
        navigate('/admin/dashboard');
      } else {
        navigate('/');
      }
    }
  }, [user, navigate]);

  // Récupération des pays autorisés
  useEffect(() => {
    fetch(
      `https://${process.env.REACT_APP_API_URL}/api/localisation/allowed-countries-details`,
    )
      .then((res) => res.json())
      .then((data: CountryItem[]) => {
        setCountriesList(data);
      })
      .catch((err) => console.error('Failed to fetch countries:', err));
  }, []);

  // Récupération du pays par défaut
  useEffect(() => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/localisation/country`)
      .then((res) => res.text())
      .then((countryString) => {
        setDefaultCountry(countryString);
      })
      .catch((err) => console.error('Failed to fetch default country:', err));
  }, []);

  // Si on trouve un pays par défaut dans la liste, on le met dans form.country
  useEffect(() => {
    if (defaultCountry && countriesList.length > 0) {
      const foundItem = countriesList.find(
        (item) =>
          item.commonName.toLowerCase() === defaultCountry.toLowerCase(),
      );
      if (foundItem) {
        setForm((prev) => ({ ...prev, country: foundItem.commonName }));
      }
    }
  }, [defaultCountry, countriesList]);

  // Vérification format phone
  function isValidPhoneNumber(phoneNumber: string): boolean {
    const cleanedPhoneNumber = phoneNumber.replace(/[\s-.]/g, '');
    const regex = /^\+[1-9]\d{1,14}$/;
    if (cleanedPhoneNumber.length < 9) {
      return false;
    }
    return regex.test(cleanedPhoneNumber);
  }

  // Handle phone change
  const handlePhoneChange = (newValue: string) => {
    setForm((prev) => ({ ...prev, phoneNumber: newValue }));
    setFieldErrors((prev) => ({ ...prev, phoneNumber: '' }));

    if (isValidPhoneNumber(newValue)) {
      setPhoneError('');
      setPhoneIsValid(true);
    } else {
      setPhoneError(t('billingInfoCard.PLEASE_ENTER_A_VALID_PHONE_NUMBER'));
      setPhoneIsValid(false);
    }
  };

  // Handle input change
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | { value: unknown }>,
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setForm((prev) => ({ ...prev, [name]: value }));
    setFieldErrors((prev) => ({ ...prev, [name]: '' }));
  };

  // Affiche ou masque le password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Regex global + pastilles
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,}$/;
  const passwordCriteria = {
    uppercase: /[A-Z]/.test(form.password),
    lowercase: /[a-z]/.test(form.password),
    special: /[^A-Za-z0-9]/.test(form.password),
    length: form.password.length >= 8,
  };

  // Validation finale
  const validateFields = (): boolean => {
    const newErrors: typeof fieldErrors = {};

    if (!form.firstname.trim()) {
      newErrors.firstname = t('THIS_FIELD_IS_REQUIRED');
    }
    if (!form.lastname.trim()) {
      newErrors.lastname = t('THIS_FIELD_IS_REQUIRED');
    }
    if (!form.email.trim()) {
      newErrors.email = t('THIS_FIELD_IS_REQUIRED');
    }

    if (!form.phoneNumber.trim()) {
      newErrors.phoneNumber = t('THIS_FIELD_IS_REQUIRED');
    } else if (!phoneIsValid) {
      newErrors.phoneNumber = 'Invalid phone format';
    }

    if (!form.password.trim()) {
      newErrors.password = t('THIS_FIELD_IS_REQUIRED');
    } else if (!passwordRegex.test(form.password)) {
      newErrors.password = t('PASSWORD_DO_NOT_MATCH_CRITERIA');
    }

    if (!form.country.trim()) {
      newErrors.country = t('THIS_FIELD_IS_REQUIRED');
    }

    if (!acceptTnc) {
      newErrors.acceptTnc = t('YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS');
    }
    if (!newsletter) {
      newErrors.newsletter = 'You must subscribe to the newsletters';
    }

    setFieldErrors(newErrors);
    return Object.keys(newErrors).length > 0;
  };

  // Soumission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const hasErrors = validateFields();
    if (hasErrors) {
      setIsSubmitting(false);
      return;
    }

    try {
      await registerUser(form);
      await login(form.email, form.password, true);
      navigate('/');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack width="100%" mt={8}>
      <form onSubmit={handleSubmit}>
        {/* Nom & prénom */}
        <Stack css={namesContainerStyles} gap={3} mb={4}>
          <TextField
            required
            id="firstname"
            label={t('LABEL_FIRSTNAME')}
            variant="outlined"
            placeholder={t('PLACEHOLDER_FIRSTNAME')}
            type="text"
            fullWidth
            autoFocus
            name="firstname"
            value={form.firstname}
            onChange={handleChange}
            error={Boolean(fieldErrors.firstname)}
            helperText={fieldErrors.firstname}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon css={iconStyles(theme)} fontSize="small" />
                </InputAdornment>
              ),
            }}
            css={textFieldStyles(theme)}
          />

          <TextField
            required
            id="lastname"
            label={t('LABEL_LASTNAME')}
            variant="outlined"
            placeholder={t('PLACEHOLDER_LASTNAME')}
            type="text"
            fullWidth
            name="lastname"
            value={form.lastname}
            onChange={handleChange}
            error={Boolean(fieldErrors.lastname)}
            helperText={fieldErrors.lastname}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon css={iconStyles(theme)} fontSize="small" />
                </InputAdornment>
              ),
            }}
            css={textFieldStyles(theme)}
          />
        </Stack>

        {/* Email, téléphone, mdp */}
        <Stack gap={4}>
          <TextField
            required
            id="email"
            label={t('LABEL_EMAIL')}
            variant="outlined"
            placeholder={t('PLACEHOLDER_EMAIL')}
            type="email"
            fullWidth
            name="email"
            value={form.email}
            onChange={handleChange}
            error={Boolean(fieldErrors.email)}
            helperText={fieldErrors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon css={iconStyles(theme)} fontSize="small" />
                </InputAdornment>
              ),
            }}
            css={textFieldStyles(theme)}
          />

          <MuiTelInput
            required
            id="phone"
            name="phoneNumber"
            label={t('LABEL_PHONE')}
            defaultCountry={
              getCode(form.country) || getCode(defaultCountry) || null
            }
            value={form.phoneNumber}
            onChange={handlePhoneChange}
            error={Boolean(fieldErrors.phoneNumber)}
            helperText={fieldErrors.phoneNumber || ''}
            fullWidth
          />

          {/* Mot de passe */}
          <TextField
            required
            id="password"
            label={t('LABEL_PASSWORD')}
            variant="outlined"
            placeholder={t('PLACEHOLDER_PASSWORD')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            name="password"
            value={form.password}
            onChange={handleChange}
            onFocus={() => setPasswordFocused(true)}
            onBlur={() => setPasswordFocused(false)}
            error={Boolean(fieldErrors.password)}
            helperText={fieldErrors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon css={iconStyles(theme)} fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            css={textFieldStyles(theme)}
          />

          {/* Liste des conditions du mot de passe, affichées seulement au focus */}
          {passwordFocused && (
            <Stack spacing={1} mt={1} mb={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: passwordCriteria.uppercase
                      ? 'green'
                      : 'red',
                  }}
                />
                <Typography variant="body2">
                  {t('AT_LEAST_ONE_UPPERCASE_LETTER')}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: passwordCriteria.lowercase
                      ? 'green'
                      : 'red',
                  }}
                />
                <Typography variant="body2">
                  {t('AT_LEAST_ONE_LOWERCASE_LETTER')}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: passwordCriteria.special ? 'green' : 'red',
                  }}
                />
                <Typography variant="body2">
                  {t('AT_LEAST_ONE_SPECIAL_CHARACTER')}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: passwordCriteria.length ? 'green' : 'red',
                  }}
                />
                <Typography variant="body2">
                  {t('AT_LEAST_HEIGHT_CHARACTERS')}
                </Typography>
              </Stack>
            </Stack>
          )}

          {/* Autocomplete pays */}
          <FormControl fullWidth>
            <Autocomplete
              options={countriesList}
              getOptionLabel={(option) => option.commonName}
              value={
                countriesList.find(
                  (item) => item.commonName === form.country,
                ) || null
              }
              onChange={(event, newValue) => {
                setForm((prev) => ({
                  ...prev,
                  country: newValue ? newValue.commonName : '',
                }));
                setFieldErrors((prev) => ({ ...prev, country: '' }));
              }}
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  key={option.countryCode}
                  value={option.countryCode}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      component="img"
                      src={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png`}
                      alt={`Flag of ${option.commonName}`}
                      sx={{
                        width: 24,
                        height: 16,
                        borderRadius: '2px',
                      }}
                    />
                    {option.commonName}
                  </Box>
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={t('LABEL_COUNTRY')}
                  variant="outlined"
                  fullWidth
                  error={Boolean(fieldErrors.country)}
                  helperText={fieldErrors.country}
                />
              )}
            />
          </FormControl>
        </Stack>

        {/* Checkboxes (T&C, newsletter) */}
        <Stack mt={1.5} mb={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTnc}
                  onChange={(e) => {
                    setAcceptTnc(e.target.checked);
                    setFieldErrors((prev) => ({
                      ...prev,
                      acceptTnc: '',
                    }));
                  }}
                />
              }
              label={
                <Typography variant="body1" component="span">
                  {t('LABEL_I_ACCEPT')}{' '}
                  <Typography
                    variant="body1"
                    component={Link}
                    to="https://raisemyfunds.co/wp-content/uploads/2024/08/TCs-RaiseMyFunds.pdf"
                    sx={{
                      textDecoration: 'underline',
                      color: theme.palette.primary.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('LABEL_TERMS_AND_CONDITIONS')}
                  </Typography>
                  <Typography variant="body1" component="span">
                    *
                  </Typography>
                </Typography>
              }
            />
            {fieldErrors.acceptTnc && (
              <Typography color="error" variant="body2">
                {fieldErrors.acceptTnc}
              </Typography>
            )}
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletter}
                  onChange={(e) => {
                    setNewsletter(e.target.checked);
                    setFieldErrors((prev) => ({
                      ...prev,
                      newsletter: '',
                    }));
                  }}
                />
              }
              label={`${t('LABEL_NEWSLETTER')}*`}
            />
            {fieldErrors.newsletter && (
              <Typography color="error" variant="body2">
                {fieldErrors.newsletter}
              </Typography>
            )}
          </FormGroup>
        </Stack>

        {/* Erreur globale si besoin */}
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}

        {/* Bouton de soumission */}
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          css={buttonStyles(theme)}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress /> : t('BUTTON_REGISTER')}
        </Button>
      </form>
    </Stack>
  );
};

export default RegisterContent;
