import React from 'react';
import { useHandleCopy } from '../hooks/useHandleCopy';
import { useHandleDownload } from '../hooks/useHandleDownload';
import { CertificateResponse } from '../services/get/certificate/models';
import { yellow } from '../../../../themes/colors/colors';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import classes from '../styles/certificate.module.scss';
import { useTranslation } from 'react-i18next';
import { CertificateCardSkeleton } from './CertificateCardSkeleton';
import { useCertificates } from '../hooks/useCertificate';

interface CertificateCardProps {
  certificate: CertificateResponse;
}

const CertificateCard: React.FC<CertificateCardProps> = ({ certificate }) => {
  const { copyText, handleCopy } = useHandleCopy();
  const { handleDownload } = useHandleDownload();
  const { loading } = useCertificates(false); // Hook qui gère le chargement
  const { t, i18n } = useTranslation('certificate');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Détecte les petits écrans

  return (
    <Card
      elevation={0}
      className={classes.cards}
      sx={{
        border: `2px solid ${
          certificate.certificateType === 'FUNDED' ? yellow[800] : '#175ADF'
        }`,
        width: isSmallScreen ? '100%' : 'auto', // Ajuste la largeur de la carte en fonction de la taille de l'écran
      }}
    >
      <CardContent>
        <Stack gap={2}>
          <Typography
            variant="button"
            className={classes.title}
            sx={{
              backgroundColor:
                certificate.certificateType === 'PAYOUT'
                  ? 'rgba(23, 90, 223, 0.3)'
                  : 'rgba(255, 224, 99, 0.3)',
              color:
                certificate.certificateType === 'PAYOUT'
                  ? '#175ADF'
                  : '#e1b811',
            }}
          >
            {certificate.certificateType}
          </Typography>

          <Box
            component="img"
            sx={{
              filter: !certificate.unlocked
                ? 'blur(18px) grayscale(0.8)'
                : 'none',
              width: isSmallScreen ? '100%' : 'auto', // Ajuste la taille de l'image pour les petits écrans
              height: 'auto',
            }}
            src={`data:image/png;base64,${certificate.image}`}
            alt={certificate.certificateType}
          />

          {certificate.unlocked && (
            <Typography
              variant="caption"
              component="p"
              color="InactiveCaptionText"
              fontWeight="bold"
            >
              {t('OBTAINED_DATE', {
                date: new Date(certificate.obtainedDate).toLocaleDateString(
                  i18n.language,
                  {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  },
                ),
              })}
            </Typography>
          )}
        </Stack>
      </CardContent>

      {certificate.unlocked && (
        <CardActions>
          <Button
            variant="text"
            startIcon={<DownloadIcon />}
            onClick={() =>
              handleDownload(certificate.image, certificate.certificateType)
            }
          >
            {t('DOWNLOAD_BUTTON')}
          </Button>

          <Button
            variant="text"
            startIcon={<ContentCopyIcon />}
            onClick={() => handleCopy(certificate.id, certificate.image)}
          >
            {copyText[certificate.id] || t('COPY_BUTTON')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default CertificateCard;
