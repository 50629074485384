import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Paper,
} from '@mui/material';
import { CalendarResponse } from '../../services/get/calendar/models';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Countdown from '../Countdown/Countdown';
import moment from 'moment';
import InstrumentIcon from '../InstrumentIcon/InstrumentIcon';
import { useTranslation } from 'react-i18next';
import EventsTableSkeleton from './EventsTableSkeleton';

interface EventsTableProps {
  selectedDays: moment.Moment[];
  impactFilter: {
    high: boolean;
    medium: boolean;
    low: boolean;
    holiday: boolean;
  };
  economicEvents: CalendarResponse[];
  loading: boolean;
  timeZone: string;
}

const EventsTable: React.FC<EventsTableProps> = ({
  selectedDays,
  impactFilter,
  economicEvents,
  loading,
  timeZone,
}) => {
  const { t } = useTranslation('calendar');

  const filteredEvents = economicEvents
    .filter((event) =>
      selectedDays.some((selectedDay) =>
        moment(event.eventDateTime).isSame(selectedDay, 'day'),
      ),
    )
    .filter((event) => {
      const impactLowerCase = event.impact.toLowerCase();
      return (
        (impactFilter.high && impactLowerCase === 'high') ||
        (impactFilter.medium && impactLowerCase === 'medium') ||
        (impactFilter.low && impactLowerCase === 'low') ||
        (impactFilter.holiday && impactLowerCase === 'holiday')
      );
    });

  if (loading) {
    return <EventsTableSkeleton />;
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#131722' }}>
          <TableRow>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              {t('TABLE.DESCRIPTION')}
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              {t('TABLE.INSTRUMENT')}
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              {t('TABLE.DATE')}
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              {t('TABLE.FORECAST')}
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              {t('TABLE.PREVIOUS')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && filteredEvents.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No events for this day
              </TableCell>
            </TableRow>
          ) : (
            filteredEvents.map((event) => (
              <TableRow key={event.id}>
                <TableCell align="left">
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Rating
                      name="simple-controlled"
                      value={
                        event.impact === 'Low'
                          ? 1
                          : event.impact === 'Medium'
                          ? 2
                          : event.impact === 'High'
                          ? 3
                          : 3
                      }
                      readOnly
                      max={3}
                      icon={
                        <StarIcon
                          fontSize="small"
                          sx={
                            event.impact === 'High'
                              ? { color: '#FF3548' }
                              : event.impact === 'Medium'
                              ? { color: '#FF9264' }
                              : event.impact === 'Low'
                              ? { color: '#FFC700' }
                              : event.impact === 'Holiday'
                              ? { color: 'transparent', stroke: '#B164FF' }
                              : { color: 'gray' }
                          }
                        />
                      }
                      emptyIcon={
                        <span
                          style={
                            event.impact === 'Holiday'
                              ? { display: 'block' }
                              : { visibility: 'hidden' }
                          }
                        >
                          {' '}
                          <StarIcon fontSize="inherit" />{' '}
                        </span>
                      }
                    />
                    {event.title}
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  <Stack alignItems={'center'} direction={'row'} gap={2}>
                    <InstrumentIcon instrumentName={event.instrument} />
                    {event.instrument === 'oilWTI'
                      ? 'OIL'
                      : event.instrument === 'USGas'
                      ? 'GAS'
                      : event.instrument}
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  {timeZone &&
                    moment(event.eventDateTime).tz(timeZone).format('ll')}
                  <Typography sx={{ color: '#797979', fontWeight: 'bold' }}>
                    <Countdown
                      targetDate={moment
                        .tz(event.eventDateTime, timeZone)
                        .toDate()}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {event.forecast ? event.forecast : t('TABLE.NO_FORECAST')}
                </TableCell>
                <TableCell align="left">
                  {event.previous
                    ? event.previous
                    : t('TABLE.NO_PREVIOUS_VALUE')}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventsTable;
