import React, { useEffect, useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

import classes from './_paymentWidget.module.scss';
import { decodeJWT, JWTPayload } from '../../pages/user/profil/services/auth/decodeJWT';

declare global {
  interface Window {
    widget: any;
  }
}

// Une promesse globale pour charger le script une seule fois
let loadWidgetScriptPromise: Promise<void> | null = null;
const loadWidgetScript = (): Promise<void> => {
  if (loadWidgetScriptPromise) return loadWidgetScriptPromise;
  loadWidgetScriptPromise = new Promise((resolve, reject) => {
    if (document.querySelector('script[src="https://unpkg.com/@paycore/merchant-widget-js@0.4.1/dist/merchantWidget.umd.min.js"]')) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@paycore/merchant-widget-js@0.4.1/dist/merchantWidget.umd.min.js';
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Erreur lors du chargement du script.'));
    document.body.appendChild(script);
  });
  return loadWidgetScriptPromise;
};

const PaymentWidget: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { getCode } = require('country-list');

  const getCustomerUUID = (): string => {
    const storedAccessToken =
      localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');

    if (storedAccessToken) {
      const decoded: JWTPayload | null = decodeJWT(storedAccessToken);
      if (decoded?.userId) {
        return decoded.userId;
      }
      console.warn('Aucun userId trouvé dans le JWT. Génération d’un UUID.');
    } else {
      console.warn('Aucun token trouvé. Génération d’un UUID.');
    }
    return uuidv4();
  };

  const customerUUID = getCustomerUUID();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    const paymentInfo = localStorage.getItem('paymentInfo');
    if (!paymentInfo) {
      setError('Aucune information de paiement trouvée.');
      return;
    }

    let parsedInfo;
    try {
      parsedInfo = JSON.parse(paymentInfo);
    } catch (e) {
      setError('Erreur de lecture des informations de paiement.');
      return;
    }

    const {
      finalAmount,
      externalId,
      email,
      firstname,
      lastname,
      phoneNumber,
      street,
      city,
      zipCode,
      country,
      birthDate,
    } = parsedInfo || {};

    if (!finalAmount || !externalId || !email || !firstname || !lastname) {
      setError('Informations de paiement incomplètes.');
      return;
    }

    const handleWidgetEvents = (event: MessageEvent) => {
      const data = event.data;
      if (typeof data === 'string') {
        console.log('EVENT reçu (string) :', data);
        if (data === 'rendered') {
          setLoading(false);
        } else if (data === 'status_page') {
          window.location.href = `https://${process.env.REACT_APP_API_URL}/buy-challenge/payment-status/${externalId}`;
        }
      } else if (typeof data === 'object' && data !== null) {
        console.log('EVENT reçu (object) :', data);
        if (data.type === 'rendered') {
          setLoading(false);
        } else if (data.type === 'status_page') {
          window.location.href = `https://${process.env.REACT_APP_API_URL}/buy-challenge/payment-status/${externalId}`;
        }
      }
    };

    const initializeWidget = () => {
      // Vider le conteneur pour forcer la recréation du widget
      const container = document.getElementById('merchant-widget-mount-point');
      if (container) {
        container.innerHTML = '';
      }
      window.addEventListener('message', handleWidgetEvents, { passive: true });
      console.log('Initialisation du widget via window.widget.init');
      window.widget.init({
        public_key: 'pk_live_Ke0q6neYt0FF4RKfqa451WyNmbu5WcckdooMrTBxVAo',
        baseUrl: 'https://com.paycore.io/hpp/',
        flow: 'iframe',
        selector: 'merchant-widget-mount-point',
        amount: finalAmount,
        currency: 'EUR',
        service: 'payment_card_eur_hpp',
        language: 'en',
        reference_id: externalId,
        description: '',
        customer: {
          reference_id: customerUUID,
          email: email,
          name: `${firstname} ${lastname}`,
          phone: phoneNumber,
          date_of_birth: birthDate,
          address: {
            full_address: `${street}, ${city}, ${zipCode}, ${country}`,
            country: getCode(country),
            city: city,
            street: street,
            post_code: zipCode,
          }
        },
        style: { theme: 'dark' },
        display: {
          hide_header: true,
          hide_footer: true,
          hide_progress_bar: true,
          hide_method_filter: true,
          hide_lifetime_counter: true,
        }
      });
    };

    loadWidgetScript()
      .then(() => {
        console.log('Script chargé, initialisation du widget');
        initializeWidget();
      })
      .catch((err: Error) => {
        setError(err.message);
      });

    return () => {
      window.removeEventListener('message', handleWidgetEvents);
    };
  }, [customerUUID, getCode]);

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContent}>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: 'white',
            color: 'black',
            width: 32,
            height: 32,
            borderRadius: '50%',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: '#eaeaea',
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        {loading && !error && (
          <div className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </div>
        )}

        {error && (
          <div className={classes.errorMessage}>
            <p>{error}</p>
          </div>
        )}

        <div id="merchant-widget-mount-point" style={{ width: '400px', height: '600px' }}></div>
      </div>
    </div>
  );
};

export default PaymentWidget;
