import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../../../../context/AuthContext/AuthContext';

import LanguageMenu from '../LanguageMenu/LanguageMenu';

import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Stack,
  Typography,
  ListItemIcon,
  ListItemText,
  Chip,
  CircularProgress,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import TranslateIcon from '@mui/icons-material/Translate';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightModeIcon from '@mui/icons-material/LightMode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ProfileMenu = () => {
  const { isDarkMode, toggleTheme } = useThemeContext();
  const { logout, profile } = useAuth();
  const { i18n, t } = useTranslation(['header']);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSecondMenuOpen, setIsSecondMenuOpen] = useState(false);
  const [navigationPath, setNavigationPath] = useState<string | null>(null);

  const languageNames = {
    en: 'English',
    fr: 'Français',
    es: 'Español',
    ar: 'العربية',
  };

  const languageFullName =
    languageNames[i18n.language as keyof typeof languageNames] || '...';

  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsSecondMenuOpen(false);
  };

  const handleOpenSecondMenu = () => {
    setIsSecondMenuOpen(true);
  };

  const handleCloseSecondMenu = () => {
    setIsSecondMenuOpen(false);
  };

  const handleMenuItemClick = (path: string) => {
    setNavigationPath(path);
    handleMenuClose();
  };

  if (!profile) {
    return <CircularProgress />;
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen} sx={{ p: 0 }}>
        <Avatar sx={{ bgcolor: '#175ADF' }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={openMenu && !isSecondMenuOpen}
        onClose={handleMenuClose}
        TransitionProps={{
          onExited: () => {
            if (navigationPath) {
              navigate(navigationPath);
              setNavigationPath(null);
            }
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: 2,
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            borderBottom: '1px solid #949494',
            padding: '8px 16px',
            marginBottom: '16px',
          }}
        >
          <Tooltip
            title={profile.email}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <Typography variant="subtitle1" component={'p'}>
              {`${profile.firstname} ${profile.lastname}`}
            </Typography>
          </Tooltip>

          <IconButton onClick={handleMenuClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <MenuItem onClick={() => handleMenuItemClick('/profile')}>
          <ListItemIcon>
            <AssignmentIndIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('MENU_PROFILE.PROFILE')}</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleMenuItemClick('/profile/kyc')}>
          <ListItemIcon>
            <FactCheckIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('MENU_PROFILE.KYC')}</ListItemText>
          <Chip
            icon={
              profile?.isKYCValidated ? (
                <CheckCircleIcon sx={{ color: 'white !important' }} />
              ) : (
                <CancelIcon sx={{ color: 'white !important' }} />
              )
            }
            label={
              profile?.isKYCValidated
                ? t('MENU_PROFILE.KYC_VALIDATED')
                : t('MENU_PROFILE.KYC_NOT_VALIDATED')
            }
            sx={{
              marginLeft: '12px',
              color: 'white',
              backgroundColor: profile?.isKYCValidated ? '#19B580' : '#e73d44',
            }}
            size="small"
          />
        </MenuItem>

        <MenuItem onClick={toggleTheme}>
          <ListItemIcon>
            {isDarkMode ? (
              <DarkModeIcon fontSize="small" />
            ) : (
              <LightModeIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>
            {isDarkMode
              ? t('MENU_PROFILE.DARK_THEME')
              : t('MENU_PROFILE.LIGHT_THEME')}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleOpenSecondMenu}>
          <ListItemIcon>
            <TranslateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t('MENU_PROFILE.LANGUAGE')} {languageFullName}
          </ListItemText>
          <ChevronRightIcon />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose();
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('MENU_PROFILE.LOG_OUT')}</ListItemText>
        </MenuItem>
      </Menu>
      <LanguageMenu
        anchorEl={anchorEl}
        open={isSecondMenuOpen}
        handleBack={handleCloseSecondMenu}
        handleClose={handleMenuClose}
      />
    </>
  );
};

export default ProfileMenu;
