import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../context/AuthContext/AuthContext';

import useBreakpoints from '../../../../hooks/useBreakpoints';

import { ChallengeAccountChart } from '../../services/get/accountChart/models';
import getAccountChart from '../../services/get/accountChart/getAccountChart';

import ZoomableChart from '../../../../components/ZoomableChart/ZoomableChart';

import {
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';

import classes from './chart.module.scss';
import { useTranslation } from 'react-i18next';
import ChartSkeleton from './ChartSkeleton';

interface ChartProps {
  elevation: number;
  selectedAccountLogin: string | null;
  showProfitTarget?: boolean; // Nouvelle prop pour afficher ou non l'annotation profitTarget
}

const Chart: React.FC<ChartProps> = ({
  elevation,
  selectedAccountLogin,
  showProfitTarget = true, // Valeur par défaut à true
}) => {
  const { t } = useTranslation('area');
  const { isBetweenXSAndSM } = useBreakpoints();
  const { tokens } = useAuth();
  const [accountChartData, setAccountChartData] =
    useState<ChallengeAccountChart | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [period, setPeriod] = useState<number>(1);

  useEffect(() => {
    if (!selectedAccountLogin) {
      setLoading(true);
      return;
    }

    const fetchAccountBaseData = async () => {
      if (selectedAccountLogin && tokens) {
        setLoading(true);
        try {
          const data = await getAccountChart(
            selectedAccountLogin,
            tokens.accessToken,
            period,
          );

          if (!data || !data.data) {
            setError(t('CHART.ERROR_ACCOUNT_CHART_DATA_IS_MISSING'));
            return;
          }

          setAccountChartData(data);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message);
          } else {
            setError(t('CHART.ERROR_AN_UNEXPECTED_ERROR_OCCURED'));
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAccountBaseData();
  }, [selectedAccountLogin, tokens, period]);

  const labels = accountChartData ? Object.keys(accountChartData.data) : [];
  const data = accountChartData ? Object.values(accountChartData.data) : [];
  const dailyLossLimitY = accountChartData?.dailyLossLimitY ?? 0;

  // Détermine le titre de l'axe X en fonction de la période sélectionnée
  const xAxisTitle =
    period === 0 ? t('CHART.AXIS_TITLE_HOURS') : t('CHART.AXIS_TITLE_DAY');

  if (loading) {
    return <ChartSkeleton elevation={elevation} />;
  }

  if (error) {
    return (
      <Card elevation={elevation} className={classes.chart}>
        <Typography color="error" textAlign="center">
          {error}
        </Typography>
      </Card>
    );
  }

  return (
    <Card elevation={elevation} className={classes.chart}>
      {accountChartData && (
        <ZoomableChart
          labels={labels}
          datasets={[
            {
              label: t('CHART.AXIS_TITLE_PROFITS'),
              data: data,
              fill: true,
              backgroundColor: 'rgba(23, 90, 223, 0.1)',
              borderColor: 'rgba(23, 90, 223, 1)',
            },
          ]}
          xAxisTitle={xAxisTitle} // Titre dynamique en fonction de la période
          yAxisTitle={t('CHART.AXIS_TITLE_PROFITS')}
          annotations={[
            {
              id: 'maxDailyLoss',
              content: `${t(
                'CHART.ANNOTATION_MAX_DAILY_LOSS',
              )} ${accountChartData?.maxDailyLoss}`,
              borderColor: 'rgb(231, 71, 80)',
              yMin: dailyLossLimitY,
              yMax: dailyLossLimitY,
            },
            {
              id: 'maxLoss',
              content: `${t(
                'CHART.ANNOTATION_MAX_LOSS',
              )} ${accountChartData?.maxOverallLoss}`,
              borderColor: 'rgb(231, 71, 80)',
              yMin: accountChartData?.maxOverallLoss || 0,
              yMax: accountChartData?.maxOverallLoss || 0,
            },
            // Conditionner l'affichage de l'annotation profitTarget
            ...(showProfitTarget
              ? [
                  {
                    id: 'profitTarget',
                    content: `${t(
                      'CHART.ANNOTATION_PROFIT_TARGET',
                    )} ${accountChartData?.profitTarget}`,
                    borderColor: 'rgb(31,204,146)',
                    yMin: accountChartData?.profitTarget || 0,
                    yMax: accountChartData?.profitTarget || 0,
                  },
                ]
              : []),
          ]}
        />
      )}
      <ButtonGroup
        sx={{ width: 'fit-content', mt: 2 }}
        variant="contained"
        size={isBetweenXSAndSM ? 'small' : 'medium'}
        aria-label="Chart period button group"
      >
        <Button onClick={() => setPeriod(0)} disabled={period === 0}>
          {t('CHART.BUTTON_TODAY')}
        </Button>
        <Button onClick={() => setPeriod(1)} disabled={period === 1}>
          {t('CHART.BUTTON_WEEK')}
        </Button>
        <Button onClick={() => setPeriod(2)} disabled={period === 2}>
          {t('CHART.BUTTON_MONTH')}
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export default Chart;
