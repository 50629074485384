import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import GetPayoutRequestsService from '../../services/get/payouts/getPayouts';
import { getCommissionsByMonth } from '../../services/get/commissions/getCommissionsByMonth';
import {
  PayoutMonthDTO,
  CommissionDetail,
  Page,
} from '../../services/get/payouts/models';
import { green, yellow } from '../../../../themes/index';
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import classes from './payoutCard.module.scss';
import { useTranslation } from 'react-i18next';
import PayoutCardSkeleton from './PayoutCardSkeleton';

const PayoutCard = () => {
  const theme = useTheme();
  const { t } = useTranslation('partnerArea');
  const { tokens } = useAuth();
  const { isXS } = useBreakpoints();

  // Local state for loading and pagination/details per payout month
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  // Use a dictionary keyed by a unique key (month-year) to track whether a card is expanded
  const [isExpanded, setIsExpanded] = useState<{ [key: string]: boolean }>({});
  // The list of payout months retrieved from the API
  const [payoutMonths, setPayoutMonths] = useState<PayoutMonthDTO[]>([]);
  // Track the current page for each month-year using a unique key
  const [currentPage, setCurrentPage] = useState<{ [key: string]: number }>({});
  // Track the commission details for each month-year using a unique key
  const [currentDetails, setCurrentDetails] = useState<{ [key: string]: CommissionDetail[] }>({});
  // Track total pages for each month-year
  const [totalPages, setTotalPages] = useState<{ [key: string]: number }>({});

  const pageSize = 10;

  /**
   * Function to determine the chip color based on the commission status.
   * ACTIVE returns green, PHASE_1 and PHASE_2 return yellow, and any other status returns the primary color.
   */
  const getChipColor = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return green[300];
      case 'PHASE_1':
      case 'PHASE_2':
        return yellow[800];
      default:
        return theme.palette.primary.main;
    }
  };

  /**
   * Fetch initial payout requests from the API.
   */
  useEffect(() => {
    setLoading(true);
    const fetchPayoutRequests = async () => {
      if (tokens) {
        try {
          const response = await GetPayoutRequestsService.getPayoutRequests(tokens.accessToken);
          setPayoutMonths(response.payoutMonths);
        } catch (error) {
          console.error('Error fetching payout data', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPayoutRequests();
  }, [tokens]);

  /**
   * Load commission details for a specific month/year.
   * @param uniqueKey - A unique key combining month and year (e.g. "February-2025").
   * @param monthValue - Numeric month value.
   * @param year - Numeric year.
   * @param pageNumber - Zero-based page number.
   */
  const loadCommissionDetails = async (
    uniqueKey: string,
    monthValue: number,
    year: number,
    pageNumber: number
  ) => {
    if (!tokens) return;
    setPageLoading(true);

    try {
      const response: Page<CommissionDetail> = await getCommissionsByMonth(
        tokens.accessToken,
        monthValue,
        year,
        pageNumber,
        pageSize
      );

      const details = response.content || [];
      setCurrentDetails((prevState) => ({
        ...prevState,
        [uniqueKey]: details,
      }));

      setTotalPages((prevState) => ({
        ...prevState,
        [uniqueKey]: response.totalPages || 1,
      }));
    } catch (error) {
      console.error('Error loading commission details', error);
    } finally {
      setPageLoading(false);
    }
  };

  /**
   * Handle click on a payout card to toggle its expanded state.
   * If commission details are not already loaded, initialize them.
   * @param uniqueKey - A unique key combining month and year.
   * @param payoutMonth - The payout month data object.
   */
  const handleCardClick = (uniqueKey: string, payoutMonth: PayoutMonthDTO) => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [uniqueKey]: !prevState[uniqueKey],
    }));

    if (!currentPage[uniqueKey]) {
      setCurrentPage((prevState) => ({ ...prevState, [uniqueKey]: 1 }));
    }

    // If commission details haven't been loaded yet, initialize with the data from payoutMonth
    if (!currentDetails[uniqueKey]) {
      setCurrentDetails((prevState) => ({
        ...prevState,
        [uniqueKey]: payoutMonth.commissionDetails.content,
      }));
      setTotalPages((prevState) => ({
        ...prevState,
        [uniqueKey]: payoutMonth.commissionDetails.totalPages,
      }));
    }
  };

  /**
   * Handle pagination page change.
   * @param event - The pagination event.
   * @param page - The new page number.
   * @param uniqueKey - A unique key combining month and year.
   * @param monthValue - Numeric month value.
   * @param year - Numeric year.
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
    uniqueKey: string,
    monthValue: number,
    year: number
  ) => {
    event.stopPropagation();
    setCurrentPage((prevState) => ({
      ...prevState,
      [uniqueKey]: page,
    }));

    // Adjust for zero-based indexing for the API (page - 1)
    loadCommissionDetails(uniqueKey, monthValue, year, page - 1);
  };

  if (loading) {
    return <PayoutCardSkeleton />;
  }

  return (
    <>
      {payoutMonths.map((payoutMonth) => {
        // Create a unique key by combining the month and year
        const uniqueKey = `${payoutMonth.month}-${payoutMonth.year}`;
        const pagesForMonth = totalPages[uniqueKey] || 1;

        return (
          <Card
            key={uniqueKey}
            elevation={0}
            onClick={() => handleCardClick(uniqueKey, payoutMonth)}
            className={classes.card}
            sx={{
              border: isExpanded[uniqueKey] ? '2px solid rgb(209, 72, 72)' : '2px solid transparent',
              transition: 'border 0.3s',
            }}
          >
            <CardContent sx={{ '&:last-child': { paddingBottom: '10px' } }}>
              <Stack
                direction={isXS ? 'column' : 'row'}
                spacing={2}
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <Typography variant="h5" component="h5">
                  {payoutMonth.month} {payoutMonth.year}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    color: payoutMonth.alreadyRequested
                      ? payoutMonth.alreadyProcessed
                        ? green[300]
                        : yellow[800]
                      : theme.palette.primary.main,
                    borderColor: payoutMonth.alreadyRequested
                      ? payoutMonth.alreadyProcessed
                        ? green[300]
                        : yellow[800]
                      : theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      borderColor: payoutMonth.alreadyRequested
                        ? payoutMonth.alreadyProcessed
                          ? green[300]
                          : yellow[800]
                        : theme.palette.primary.main,
                    },
                  }}
                >
                  {payoutMonth.amount.toFixed(2)} € |{' '}
                  {payoutMonth.alreadyRequested
                    ? payoutMonth.alreadyProcessed
                      ? t('PAYOUT_CARD.STATUS_PROCESSED')
                      : t('PAYOUT_CARD.STATUS_IN_REVIEW')
                    : t('PAYOUT_CARD.STATUS_NOT_AVAILABLE')}
                </Button>
              </Stack>
            </CardContent>
            <Collapse in={isExpanded[uniqueKey]} timeout="auto" unmountOnExit>
              <Divider variant="middle" sx={{ marginTop: '16px' }} />
              <CardContent sx={{ marginTop: '10px' }}>
                {pageLoading ? (
                  <Stack justifyContent="center" alignItems="center" height={300} width="100%">
                    <CircularProgress />
                  </Stack>
                ) : (
                  <TableContainer component={Paper} elevation={0} sx={{ marginBottom: 6 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="commission table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">{t('PAYOUT_CARD.TABLE_HEAD_ID')}</TableCell>
                          <TableCell align="left">{t('PAYOUT_CARD.TABLE_HEAD_NAME')}</TableCell>
                          <TableCell align="left">{t('PAYOUT_CARD.TABLE_HEAD_PRICE_PAID')}</TableCell>
                          <TableCell align="left">{t('PAYOUT_CARD.TABLE_HEAD_COMMISSION_EARNED')}</TableCell>
                          <TableCell align="left">{t('PAYOUT_CARD.TABLE_HEAD_PHASE')}</TableCell>
                          <TableCell align="left">{t('PAYOUT_CARD.TABLE_HEAD_TRANSACTION_DATE')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentDetails[uniqueKey]?.map((commission) => (
                          <TableRow key={commission.id}>
                            <TableCell align="left">{commission.id}</TableCell>
                            <TableCell align="left">{commission.name}</TableCell>
                            <TableCell align="left">{commission.pricePaid}</TableCell>
                            <TableCell align="left">{commission.commissionEarned}</TableCell>
                            <TableCell align="left">
                              <Chip
                                label={commission.status.replace('_', ' ')}
                                sx={{
                                  backgroundColor: getChipColor(commission.status),
                                  color: theme.palette.getContrastText(getChipColor(commission.status)),
                                }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {new Date(commission.transactionDate).toLocaleDateString()}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {pagesForMonth > 1 && (
                  <Pagination
                    count={pagesForMonth}
                    page={currentPage[uniqueKey] || 1}
                    variant="outlined"
                    shape="rounded"
                    disabled={pageLoading}
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event, page) =>
                      handlePageChange(
                        event,
                        page,
                        uniqueKey,
                        payoutMonth.monthValue,
                        Number(payoutMonth.year)
                      )
                    }
                  />
                )}
              </CardContent>
            </Collapse>
          </Card>
        );
      })}
    </>
  );
};

export default PayoutCard;
