import axiosInstance from '../../../../../utils/axios';
import { RegisterRequest, JWTPayload } from './models';
import { LoginResponse } from './interfaces';
import { decodeJWT } from '../../utils/decodedJWT';
import { handleRegisterError } from './errors';

export const registerUser = async (
  data: RegisterRequest,
): Promise<{ tokens: LoginResponse; user: JWTPayload }> => {
  try {
    // Envoie la requête d'inscription
    const response = await axiosInstance.post<LoginResponse>(
      `/users/register`,
      data,
    );
    const tokens = response.data;

    // Décodage du JWT pour obtenir les informations utilisateur
    const decoded = decodeJWT(tokens.accessToken);

    if (!decoded) {
      throw new Error('Failed to decode the JWT.');
    }

    return { tokens, user: decoded };
  } catch (error) {
    handleRegisterError(error); // Gestion des erreurs
    throw new Error('Registration failed due to an error');
  }
};
