import React from 'react';
import { green, red } from '../../../../themes';
import {
  Card,
  Chip,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import classes from './rulesCard.module.scss';
import { useTranslation, Trans } from 'react-i18next';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface RulesCardProps {
  title: string;
  currentValue: string | number;
  limitValue?: string | number;
  percentage: number;
  passed: boolean;
  isCustom45Percent?: boolean; // Nouvelle prop pour afficher la progressbar custom
  customLabel?: string; // Nouvelle prop pour le label personnalisé
  customLabelColor?: string; // Nouvelle prop pour la couleur personnalisée
  icon?: boolean;
  minimumPayoutValue?: number;
  maxDailyProfit?: number;
}

const RulesCard: React.FC<RulesCardProps> = ({
  title,
  currentValue,
  limitValue,
  percentage,
  passed,
  isCustom45Percent = false, // Utilisation de la prop custom
  customLabel, // Utilisation du label personnalisé
  customLabelColor, // Utilisation de la couleur personnalisée
  icon = false,
  minimumPayoutValue,
  maxDailyProfit,
}) => {
  const { t } = useTranslation('area');

  // Définir la position fixe à 45%
  const fixedPosition = '45%';

  // Définir l'alignement du texte
  const textAlign = 'center';

  return (
    <Card className={classes.ruleCard} elevation={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Typography variant="body1" component="h3" sx={{ color: '#7CAAD8' }}>
            {title}
          </Typography>
          {icon && (
            <Tooltip
              title={
                <span style={{ fontSize: '15px' }}>
                  <Trans
                    i18nKey="RULES.RULE_CARD.TOOLTIP_HELP_TEXT"
                    ns="area"
                    values={{ maxDailyProfit, minimumPayoutValue }}
                    components={{
                      coloredText: (
                        <span
                          style={{ color: '#81C784', fontWeight: 'medium' }}
                        />
                      ),
                    }}
                  />
                </span>
              }
              placement="top"
              enterTouchDelay={0}
              leaveTouchDelay={30000}
            >
              <HelpOutlineIcon fontSize="small" color="primary" />
            </Tooltip>
          )}
        </Stack>
        <Chip
          label={
            customLabel ||
            (passed
              ? t('RULES.RULE_CARD.CHIP_PASSED')
              : t('RULES.RULE_CARD.CHIP_FAILED'))
          } // Priorité au label personnalisé
          size="small"
          sx={{
            backgroundColor:
              customLabelColor || (passed ? green[300] : red[300]), // Priorité à la couleur personnalisée
            color: 'black',
          }}
        />
      </Stack>
      {limitValue && (
        <Typography variant="caption" component="h4" color="text.secondary">
          {t('RULES.RULE_CARD.TEXT_LIMIT_VALUE')} {limitValue}
        </Typography>
      )}

      <Stack mt={2} sx={{ position: 'relative' }}>
        {/* Vérification si isCustom45Percent est true */}
        {isCustom45Percent ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" component="p">
                {currentValue}% left
              </Typography>
              <Typography variant="caption" component="p">
                {percentage}%
              </Typography>
            </Stack>

            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '8px',
                marginBottom: 20,
              }}
            >
              <LinearProgress
                variant="determinate"
                value={Math.min(percentage, 100)}
              />

              {/* Barre blanche toujours à 45% */}
              <div
                style={{
                  position: 'absolute',
                  top: '-4px', // Ajusté pour faire dépasser de 4px en haut
                  left: fixedPosition, // Fixé à 45%
                  height: '12px', // Hauteur ajustée pour dépasser de 4px en haut et en bas
                  width: '4px', // Largeur de la barre maintenue à 4px
                  backgroundColor: 'white', // Barre blanche
                  zIndex: 1,
                  borderRadius: 2,
                }}
              />

              {/* Texte "45%" toujours aligné avec la barre blanche */}
              <div
                style={{
                  position: 'absolute',
                  top: '-28px', // Ajusté pour avoir 4px entre le texte et la barre
                  left: fixedPosition,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: textAlign,
                  pointerEvents: 'none',
                  transform: 'translateX(-50%)', // Centrer par rapport à la barre blanche
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  45%
                </Typography>
              </div>
            </div>

            {/* Texte "Minimum Profit" toujours sous la barre blanche à 45% */}
            <Typography
              variant="caption"
              color="text.secondary"
              align="center"
              sx={{ marginTop: '-12px' }} // Réduction de la marge
            >
              {t('RULES.RULE_CARD.TEXT_MINIMUM_PROFIT', {
                profit: minimumPayoutValue,
              })}
              {/* Minimum Profit: ${minimumPayoutValue} */}
            </Typography>
          </>
        ) : (
          <Stack gap={0.5}>
            <Typography variant="caption" component="p">
              {percentage}%
            </Typography>
            <LinearProgress
              variant="determinate"
              value={Math.min(percentage, 100)}
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default RulesCard;
