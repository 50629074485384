import { css } from '@emotion/react';

// Styles pour la carte
export const cardStyles = css`
  width: 30%;
  padding: 64px;
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 600px;

  & > img {
    margin-top: 16px;
  }

  @media (max-width: 1900px) {
    width: 40%;
    padding: 48px;
  }

  @media (max-width: 1440px) {
    width: 50%;
    padding: 48px;
  }

  @media (max-width: 1024px) {
    width: 70%;
    padding: 48px;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-height: 100vh;
    padding: 32px;
    padding-bottom: 70px;
  }
`;

export const cardWrapperStyles = css`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;
