import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { FundedAccountBaseProvider } from '../../provider/FundedAccountBaseDataProvider/FundedAccountBaseDataProvider';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { getFundedChallenges } from '../../services/get/fundedChallenges/getFundedChallenges';
import {
  ChallengeCard,
  FundedRules,
  FundedStatsCard,
  Calendar,
  Summary,
  Chart,
  TradingHistory,
  AccordionCard,
  HeaderCards,
} from '../../index';
import { Stack, Typography } from '@mui/material';
import RadarIcon from '@mui/icons-material/Radar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import classes from '../styles/area.module.scss';
import { FundedSideCardDTO } from '../../services/get/fundedChallenges/models';
import Error from '../../../../components/layouts/Error/Error';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import getTradeHistory from '../../services/get/tradeHistory/getTradeHistory';
import ChallengeSkeleton from '../../layouts/ChallengeSkeleton/ChallengeSkeleton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from "react-router-dom";

const FundedArea = () => {
  const { isXL, isBetweenXSAndLG } = useBreakpoints();
  const { t } = useTranslation(['area', 'errors']);
  const { tokens } = useAuth();
  const [selectedAccountLogin, setSelectedAccountLogin] = useState<
    string | null
  >(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth() + 1,
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear(),
  );
  const [tradeHistory, setTradeHistory] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fundedAccounts, setFundedAccounts] = useState<FundedSideCardDTO[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useDocumentTitle('Trader Area');

  useEffect(() => {
    const fetchFundedChallenges = async () => {
      if (tokens) {
        try {
          const fundedAccountsData = await getFundedChallenges(
            tokens.accessToken,
          );
          setFundedAccounts(fundedAccountsData);
          if (fundedAccountsData.length > 0) {
            setSelectedAccountLogin(fundedAccountsData[0].login);
          }
        } catch (error) {
          console.error('Failed to fetch funded accounts', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchFundedChallenges();
  }, [tokens]);

  // Fonction pour récupérer l'historique des trades
  const fetchTradeHistory = async () => {
    if (selectedAccountLogin && tokens) {
      try {
        const params: any = { page: currentPage - 1, size: 10 };
        if (selectedDate) {
          params.date = selectedDate;
        } else {
          params.month = currentMonth;
          params.year = currentYear;
        }

        setTradeHistory([]);
        const tradeHistoryData = await getTradeHistory.getTradeHistory(
          selectedAccountLogin,
          tokens.accessToken,
          params,
        );
        setTradeHistory(tradeHistoryData.content);
        setTotalPages(tradeHistoryData.totalPages);
      } catch (error) {
        console.error('Failed to fetch trade history', error);
      }
    }
  };

  useEffect(() => {
    fetchTradeHistory();
  }, [
    selectedAccountLogin,
    selectedDate,
    currentMonth,
    currentYear,
    currentPage,
  ]);

  const handleSelectAccount = (accountLogin: string) => {
    setSelectedAccountLogin(accountLogin);
    resetSelectedDate(); // Réinitialise la date sélectionnée à chaque changement de compte
    setCurrentMonth(new Date().getMonth() + 1); // Mois actuel
    setCurrentYear(new Date().getFullYear()); // Année actuelle
  };

  const resetSelectedDate = () => {
    setSelectedDate(null);
  };

  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
  };

  const handleMonthYearChange = (month: number, year: number) => {
    setCurrentMonth(month);
    setCurrentYear(year);
    setSelectedDate(null);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const resetPagination = () => {
    setCurrentPage(1); // Réinitialiser la pagination à la première page
  };

  return (
    <>
      <HeaderCards />
      {/* SI PAS DE COMPTE & PAS EN CHARGEMENT */}
      {!loading && fundedAccounts.length === 0 ? (
        <div className={classes.noAccountContainer}>
          {/* Icône Wallet qui rebondit */}
          <div className={classes.walletIconBounce}>
            <AccountBalanceWalletIcon style={{ fontSize: '32px', color: '#fff' }} />
          </div>

          {/* Titre : "Vous n'avez pas encore de compte" */}
          <h2 className={classes.noAccountTitle}>
            {t('YOU_DONT_HAVE_AN_FUNDED_ACCOUNT_YET', { ns: 'errors' })}
          </h2>

          {/* Sous-titre : "Commencez votre voyage trading dès aujourd'hui" */}
          <p className={classes.noAccountSubtitle}>
            {t('START_YOUR_TRADING_JOURNEY_TODAY', { ns: 'errors' })}
          </p>

          {/* Bouton : "Acheter un challenge" */}
          <button
            type="button"
            className={classes.noAccountButton}
            onClick={() => {
              navigate('/buy-challenge')
            }}
          >
            {t('BUY_CHALLENGE', { ns: 'errors' })}
          </button>
        </div>
      ) : (
        <FundedAccountBaseProvider selectedAccountLogin={selectedAccountLogin!}>
          {isXL ? (
            <>
              <Stack className={classes.parent} direction={'row'} gap={4}>
                <Stack className={classes.panel}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <RadarIcon />
                    <Typography variant="h5" component="h2" alignItems="center">
                      {t('TITLES.FUNDED_ACCOUNTS')}
                    </Typography>
                  </Stack>
                  <Stack className={classes.panelInner} mt={6}>
                    {loading ? (
                      <ChallengeSkeleton />
                    ) : (
                      <Stack gap={2} className={classes.cardsContainer}>
                        <ChallengeCard
                          onSelect={handleSelectAccount}
                          onResetDate={resetSelectedDate}
                          challenges={fundedAccounts}
                          isFunded={true}
                          selectedAccountLogin={selectedAccountLogin}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Stack className={classes.content} gap={2}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <DashboardIcon />
                    <Typography variant="h5" component="h2" alignItems="center">
                      {t('TITLES.DASHBOARD')}
                    </Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Chart
                      elevation={0}
                      selectedAccountLogin={selectedAccountLogin}
                      showProfitTarget={false}
                    />
                    <FundedRules
                      elevation={0}
                      selectedAccountLogin={selectedAccountLogin}
                    />
                  </Stack>

                  <FundedStatsCard elevation={0} />
                </Stack>
              </Stack>
              <Stack
                mt={4}
                mb={4}
                direction="row"
                justifyContent="space-between"
                gap={2}
              >
                <Calendar
                  elevation={0}
                  selectedAccountLogin={selectedAccountLogin!}
                  onSelectDate={handleSelectDate}
                  onMonthYearChange={handleMonthYearChange}
                  onResetPagination={resetPagination}
                  currentMonth={currentMonth} // Pass current month
                  currentYear={currentYear} // Pass current year
                />
                <Summary
                  selectedAccountLogin={selectedAccountLogin!}
                  selectedDate={selectedDate}
                  onTradeHistoryFetched={setTradeHistory}
                  tableRef={tableRef}
                  inAccordion={false}
                />
              </Stack>
              <div ref={tableRef}>
                <TradingHistory
                  elevation={0}
                  selectedAccountLogin={selectedAccountLogin!}
                  selectedDate={selectedDate}
                  tradeHistory={tradeHistory}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <Stack gap={2}>
              <Stack direction="row" alignItems="center" mt={6} gap={1}>
                <RadarIcon />
                <Typography variant="h5" component="h2" alignItems="center">
                  {t('TITLES.FUNDED_ACCOUNTS')}
                </Typography>
              </Stack>
              {loading ? (
                <ChallengeSkeleton />
              ) : (
                <AccordionCard
                  onSelect={handleSelectAccount}
                  onResetDate={resetSelectedDate}
                  challenges={fundedAccounts}
                  isFunded={true}
                >
                  <Stack gap={2}>
                    <Stack
                      direction={isBetweenXSAndLG ? 'column' : 'row'}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Chart
                        elevation={1}
                        selectedAccountLogin={selectedAccountLogin!}
                        showProfitTarget={false}
                      />
                      <FundedRules
                        elevation={1}
                        selectedAccountLogin={selectedAccountLogin!}
                      />
                    </Stack>
                    <FundedStatsCard elevation={1} />
                  </Stack>
                  <Stack mt={4}>
                    <Calendar
                      elevation={1}
                      selectedAccountLogin={selectedAccountLogin!}
                      onSelectDate={handleSelectDate}
                      onMonthYearChange={handleMonthYearChange}
                      onResetPagination={resetPagination}
                      currentMonth={currentMonth} // Pass current month
                      currentYear={currentYear} // Pass current year
                    />
                    <Summary
                      selectedAccountLogin={selectedAccountLogin!}
                      selectedDate={selectedDate}
                      onTradeHistoryFetched={setTradeHistory}
                      tableRef={tableRef}
                      inAccordion={true}
                    />
                  </Stack>
                  <div ref={tableRef}>
                    <TradingHistory
                      elevation={1}
                      selectedAccountLogin={selectedAccountLogin!}
                      selectedDate={selectedDate}
                      tradeHistory={tradeHistory}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </AccordionCard>
              )}
            </Stack>
          )}
        </FundedAccountBaseProvider>
      )}
    </>
  );
};

export default FundedArea;
