import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';

interface DaysSelectorProps {
  selectedDays: moment.Moment[];
  setSelectedDays: React.Dispatch<React.SetStateAction<moment.Moment[]>>;
}

const DaysSelector: React.FC<DaysSelectorProps> = ({
  selectedDays,
  setSelectedDays,
}) => {
  const { t, i18n } = useTranslation('calendar');

  moment.locale(i18n.language);

  const daysOfWeek = Array.from({ length: 5 }, (_, index) =>
    moment().startOf('isoWeek').add(index, 'days'),
  );

  const handleDayClick = (day: moment.Moment) => {
    const isSelected = selectedDays.some((selectedDay) =>
      selectedDay.isSame(day, 'day'),
    );
    if (isSelected) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => !selectedDay.isSame(day, 'day')),
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <Stack direction="row" width="100%" sx={{ overflowY: 'scroll' }}>
      {daysOfWeek.map((day, index) => {
        const isSelected = selectedDays.some((selectedDay) =>
          selectedDay.isSame(day, 'day'),
        );

        return (
          <Paper
            key={index}
            elevation={0}
            onClick={() => handleDayClick(day)}
            sx={{
              flex: 1,
              textAlign: 'center',
              padding: '2vw',
              border: '1px solid',
              borderColor: '#9A9A9A',
              color: isSelected ? '#175ADF' : '#9A9A9A',
              borderTop: isSelected ? '5px solid #175ADF' : '1px solid #9A9A9A',
              cursor: 'pointer',
              borderRadius: '0px',
              '&:hover': {
                borderTop: '5px solid #175ADF',
              },
            }}
          >
            <Stack gap={2}>
              <Typography variant="h5" component={'h2'} fontWeight={'bold'}>
                {moment(day).format('dddd').toUpperCase()}
              </Typography>
              <Typography variant="h4" component={'h3'} fontWeight={'bold'}>
                {moment(day).format('DD')}
              </Typography>
              <Typography variant="h5" component={'h4'} fontWeight={'bold'}>
                {moment(day).format('MMMM').toUpperCase()}
              </Typography>
            </Stack>
          </Paper>
        );
      })}
    </Stack>
  );
};

export default DaysSelector;
