/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import { Box, Stack, Typography, useTheme } from '@mui/material';

import { buttonStyles, sliderStyles } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';

const ButtonToggle: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [sliderPosition, setSliderPosition] = useState<number>(
    location.pathname === '/register' ? 1 : 0,
  );

  useEffect(() => {
    if (location.pathname === '/login') {
      setSliderPosition(0);
    } else if (location.pathname === '/register') {
      setSliderPosition(1);
    }
  }, [location.pathname]);

  const handleClick = (path: string, position: number) => {
    if (path !== location.pathname) {
      setSliderPosition(position);
      setTimeout(() => navigate(path), 100);
    }
  };

  return (
    <Stack
      direction={'row'}
      p={0.8}
      gap={2}
      width={'100%'}
      position={'relative'}
      bgcolor={theme.palette.background.default}
      borderRadius={'4px'}
      // overflow={'hidden'}
    >
      <Box
        css={sliderStyles({ sliderPosition, theme })}
        position={'absolute'}
        height={'100%'}
      />

      <Box
        onClick={() => handleClick('/login', 0)}
        css={buttonStyles({
          isActive: location.pathname === '/login',
          theme,
        })}
      >
        <Typography component={'p'} variant="button">
          SIGN IN
        </Typography>
      </Box>

      <Box
        onClick={() => handleClick('/register', 1)}
        css={buttonStyles({
          isActive: location.pathname === '/register',
          theme,
        })}
      >
        <Typography component={'p'} variant="button">
          REGISTER
        </Typography>
      </Box>
    </Stack>
  );
};

export default ButtonToggle;
