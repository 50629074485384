import { Theme } from '@mui/material/styles';

import { css, SerializedStyles } from '@emotion/react';

interface ButtonStylesProps {
  isActive: boolean;
  theme: Theme;
}

export const buttonStyles = ({
  isActive,
  theme,
}: ButtonStylesProps): SerializedStyles => css`
  position: relative;
  z-index: 1;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: transparent;
  color: ${isActive ? 'white' : theme.palette.text.primary};

  &:hover {
    background-color: ${!isActive ? theme.palette.action.hover : 'transparent'};
  }
`;

interface SliderStylesProps {
  sliderPosition: number;
  theme: Theme;
}

export const sliderStyles = ({
  sliderPosition,
  theme,
}: SliderStylesProps): SerializedStyles => css`
  position: absolute;
  top: 6px;
  left: 6px;
  bottom: 6px;
  width: calc(50% - 6px);
  height: calc(100% - 13px);
  background-color: ${theme.palette.primary.main};
  border-radius: 4px;
  transform: translateX(${sliderPosition * 100}%);
  transition: transform 0.1s ease-in-out;
  z-index: 0;
`;
