import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { commonThemeOptions } from './commonTheme';

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      paper: '#1A1F2D',
      default: '#131722',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          backgroundColor: '#1A1F2D',
        },
        elevation1: {
          background: '#131722',
          boxShadow: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#1B1B22',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #697399',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #697399',
        },
      },
    },
  },
};

export const darkTheme = createTheme(darkThemeOptions, commonThemeOptions);
