import React from 'react';
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  IconButton,
  List,
  ListItem,
  Card,
  CardMedia,
  useMediaQuery,
  useTheme,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSupportForm } from '../../hooks/useSupportForm';
import FileUpload from '../FileUpload/FileUpload';
import { useTranslation } from 'react-i18next';

import enFlag from '../../../../assets/icons/flags/en.png';
import frFlag from '../../../../assets/icons/flags/fr.png';

interface SupportModalProps {
  openSupportModal: boolean;
  handleClose: () => void;
}

const SupportModal: React.FC<SupportModalProps> = ({
  openSupportModal,
  handleClose,
}) => {
  const { t } = useTranslation('support');

  const {
    subject,
    setSubject,
    content,
    setContent,
    files,
    handleFileChange,
    handleFileRemove,
    handleSubmit,
    isLoading,
    error,
    snackbarMessage,
    setSnackbarMessage,
    subjectError,
    contentError,
  } = useSupportForm();

  const handleSnackbarClose = () => {
    setSnackbarMessage(null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      open={openSupportModal}
      onClose={handleClose}
      aria-labelledby="support-modal-title"
      aria-describedby="support-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90%' : 600, // Adjust width for mobile
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: isMobile ? 2 : 4, // Adjust padding for mobile
          borderRadius: '8px',
        }}
      >
        <Typography variant="h6" id="support-modal-title" gutterBottom>
          {t('TITLE_SUPPORT')}
        </Typography>

        {error && <Typography color="error">{error}</Typography>}

        {/* Sujet du ticket */}
        <TextField
          label={t('LABEL_SUBJECT')}
          variant="outlined"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          error={!!subjectError}
          helperText={subjectError}
          sx={{ marginBottom: 2 }}
        />

        {/* Contenu du ticket */}
        <TextField
          label={t('LABEL_CONTENT')}
          variant="outlined"
          fullWidth
          multiline
          rows={isMobile ? 3 : 5} // Adjust rows for mobile
          value={content}
          onChange={(e) => setContent(e.target.value)}
          error={!!contentError}
          helperText={contentError}
          sx={{ marginBottom: 2 }}
        />

        <Stack gap={2}>
          {/* Upload des fichiers */}
          <FileUpload
            files={files}
            handleFileChange={handleFileChange}
            handleFileRemove={handleFileRemove}
          />

          {/* Bouton de soumission */}
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            disabled={isLoading || !subject || !content}
          >
            {isLoading ? <CircularProgress size={24} /> : t('BUTTON_SEND')}
          </Button>
        </Stack>

        <Stack mt={4} direction={'row'} alignItems={'center'} gap={1}>
          <Stack direction={'row'} gap={0.2}>
            <Box
              component="img"
              sx={{
                height: '18px',
                width: '24px',
                borderRadius: '2px',
                marginRight: '8px',
              }}
              alt="RaiseMyFunds logo"
              src={enFlag}
            />

            <Box
              component="img"
              sx={{
                height: '18px',
                width: '24px',
                borderRadius: '2px',
                marginRight: '8px',
              }}
              alt="RaiseMyFunds logo"
              src={frFlag}
            />
          </Stack>
          <Typography
            variant={'body1'}
            component={'p'}
            fontWeight={'bold'}
            color={'text.secondary'}
          >
            {t('TEXT_OR_CONTACT_US_AT', { phone: '+44 141 673 5915' })}
          </Typography>
        </Stack>

        <Snackbar
          open={!!snackbarMessage}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Box>
    </Modal>
  );
};

export default SupportModal;
