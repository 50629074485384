import { Theme } from '@mui/material/styles';
import { css, SerializedStyles } from '@emotion/react';

// Styles pour le TextField
export const textFieldStyles = (theme: Theme): SerializedStyles => css`
  & .MuiInputBase-root {
    &.Mui-focused .MuiInputAdornment-positionStart .MuiSvgIcon-root {
      color: ${theme.palette.primary
        .main}; // Couleur primaire uniquement pour les icônes du startAdornment
    }
  }
`;

// Styles pour l'icône
export const iconStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.palette.grey[500]}; // Gris par défaut
`;

// Styles pour le conteneur du bouton
export const buttonContainerStyles = css`
  position: relative;
  display: inline-block; /* Assure un conteneur autour du bouton */
  z-index: 0; /* Le conteneur gère le contexte pour le bouton et son ombre */
`;

export const namesContainerStyles = css`
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

// Styles pour le bouton
export const buttonStyles = (theme: Theme): SerializedStyles => css`
  position: relative; /* Crée un contexte d'empilement pour le bouton et son ombre */
  overflow: visible;
  border: none;
  background: ${theme.palette.background.paper};
  padding: 14px 24px;
  border-radius: 12px;
  font-weight: 500;
  color: ${theme.palette.text.primary};
  cursor: pointer;
  /* z-index: 1; Assure que le bouton reste au-dessus de l'ombre */

  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: inherit; /* Applique le même radius que le bouton */
    background-image: linear-gradient(45deg, #3b82f6, #a855f7); /* Dégradé */
    filter: blur(8px); /* Ombre floue */
    z-index: -1; /* Place derrière le bouton, mais visible au-dessus de la carte */
    opacity: 0.6;
    pointer-events: none; /* Empêche toute interaction avec l'ombre */
    transition:
      filter 0.3s ease,
      opacity 0.3s ease; /* Transition fluide */
  }

  &:hover::after {
    filter: blur(10px);
    opacity: 1; /* Rend l'ombre plus visible au survol */
  }

  &:hover {
    background: ${theme.palette.background.paper};
    border: none;
  }
`;
