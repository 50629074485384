import React, { useEffect, useState, useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import RadarIcon from '@mui/icons-material/Radar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from "react-router-dom";

import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { AccountBaseDataProvider } from '../../provider/AccountBaseDataProvider/AccountBaseDataProvider';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

import { getChallenges } from '../../services/get/challenges/getChallenges';
import getTradeHistory from '../../services/get/tradeHistory/getTradeHistory';
import { AccountBase } from '../../services/get/challenges/models';

import {
  ChallengeCard,
  Rules,
  StatsCard,
  Calendar,
  Summary,
  Chart,
  TradingHistory,
  AccordionCard,
  HeaderCards,
} from '../../index';
import ChallengeSkeleton from '../../layouts/ChallengeSkeleton/ChallengeSkeleton';

import { useTranslation } from 'react-i18next';

import classes from '../styles/area.module.scss';

const ClientArea = () => {
  const { isXL, isBetweenXSAndLG } = useBreakpoints();
  const { t } = useTranslation(['area', 'errors']);
  const { tokens } = useAuth();

  const [selectedAccountLogin, setSelectedAccountLogin] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [tradeHistory, setTradeHistory] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [challengeAccounts, setChallengeAccounts] = useState<AccountBase[]>([]);
  const [loading, setLoading] = useState(true);

  const tableRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useDocumentTitle('Client Area');

  /**
   * Récupération des comptes (challenges) existants.
   */
  useEffect(() => {
    const fetchAccounts = async () => {
      if (tokens) {
        try {
          const challengeAccountsData = await getChallenges(tokens.accessToken);
          setChallengeAccounts(challengeAccountsData);

          if (challengeAccountsData.length > 0) {
            // On essaie de trouver le premier compte qui n'est pas en phase "SELECT_COMMISSION", "CREATING" ou "FAILED"
            const firstCheckedChallenge = challengeAccountsData.find(
              (challenge) =>
                challenge.phase !== 'SELECT_COMMISSION' &&
                challenge.phase !== 'CREATING' &&
                challenge.phase !== 'FAILED'
            )?.login;

            if (firstCheckedChallenge) {
              setSelectedAccountLogin(firstCheckedChallenge);
            }
          }
        } catch (error) {
          console.error('Failed to fetch challenge accounts', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [tokens]);

  /**
   * Récupération de l'historique de trading pour le compte sélectionné.
   */
  const fetchTradeHistory = async () => {
    if (selectedAccountLogin && tokens) {
      try {
        const params: any = { page: currentPage - 1, size: 10 };

        if (selectedDate) {
          params.date = selectedDate;
        } else {
          params.month = currentMonth;
          params.year = currentYear;
        }

        setTradeHistory([]);
        const tradeHistoryData = await getTradeHistory.getTradeHistory(
          selectedAccountLogin,
          tokens.accessToken,
          params
        );
        setTradeHistory(tradeHistoryData.content);
        setTotalPages(tradeHistoryData.totalPages);
      } catch (error) {
        console.error('Failed to fetch trade history', error);
      }
    }
  };

  useEffect(() => {
    fetchTradeHistory();
  }, [selectedAccountLogin, selectedDate, currentMonth, currentYear, currentPage]);

  /**
   * Gestion de la sélection d'un compte (challenge).
   */
  const handleSelectAccount = (accountLogin: string) => {
    setSelectedAccountLogin(accountLogin);
    resetSelectedDate();
    // Remettre le mois et l'année au mois et à l'année actuels
    setCurrentMonth(new Date().getMonth() + 1);
    setCurrentYear(new Date().getFullYear());
  };

  const resetSelectedDate = () => {
    setSelectedDate(null);
  };

  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
  };

  const handleMonthYearChange = (month: number, year: number) => {
    setCurrentMonth(month);
    setCurrentYear(year);
    setSelectedDate(null);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const resetPagination = () => {
    setCurrentPage(1);
  };

  /**
   * Affichage
   */
  return (
    <>
      <HeaderCards />

      {/* SI PAS DE COMPTE & PAS EN CHARGEMENT */}
      {!loading && challengeAccounts.length === 0 ? (
        <div className={classes.noAccountContainer}>
          {/* Icône Wallet qui rebondit */}
          <div className={classes.walletIconBounce}>
            <AccountBalanceWalletIcon style={{ fontSize: '32px', color: '#fff' }} />
          </div>

          {/* Titre : "Vous n'avez pas encore de compte" */}
          <h2 className={classes.noAccountTitle}>
            {t('YOU_DONT_HAVE_AN_ACCOUNT_YET', { ns: 'errors' })}
          </h2>

          {/* Sous-titre : "Commencez votre voyage trading dès aujourd'hui" */}
          <p className={classes.noAccountSubtitle}>
            {t('START_YOUR_TRADING_JOURNEY_TODAY', { ns: 'errors' })}
          </p>

          {/* Bouton : "Acheter un challenge" */}
          <button
            type="button"
            className={classes.noAccountButton}
            onClick={() => {
              navigate('/buy-challenge')
            }}
          >
            {t('BUY_CHALLENGE', { ns: 'errors' })}
          </button>
        </div>
      ) : (
        // SINON, ON AFFICHE LE DASHBOARD
        <AccountBaseDataProvider selectedAccountLogin={selectedAccountLogin}>
          {isXL ? (
            <>
              {/* Mise en page "desktop" */}
              <Stack className={classes.parent} direction="row" gap={4}>
                {/* Panneau gauche : liste des Challenges */}
                <Stack className={classes.panel}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <RadarIcon />
                    <Typography variant="h5" component="h2" alignItems="center">
                      {t('TITLES.ACCOUNTS')}
                    </Typography>
                  </Stack>
                  <Stack className={classes.panelInner} mt={6}>
                    {loading ? (
                      <ChallengeSkeleton />
                    ) : (
                      <Stack gap={2} className={classes.cardsContainer}>
                        <ChallengeCard
                          onSelect={handleSelectAccount}
                          onResetDate={resetSelectedDate}
                          challenges={challengeAccounts}
                          isFunded={false}
                          selectedAccountLogin={selectedAccountLogin}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                {/* Zone centrale : dashboard (charts, stats, règles, etc.) */}
                <Stack className={classes.content} gap={2}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <DashboardIcon />
                    <Typography variant="h5" component="h2" alignItems="center">
                      {t('TITLES.DASHBOARD')}
                    </Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Chart elevation={0} selectedAccountLogin={selectedAccountLogin} />
                    <Rules elevation={0} selectedAccountLogin={selectedAccountLogin} />
                  </Stack>

                  <StatsCard elevation={0} />
                </Stack>
              </Stack>

              {/* Section sous le dashboard (Calendrier + Résumé + Historique) */}
              <Stack mt={4} mb={4} direction="row" justifyContent="space-between" gap={2}>
                <Calendar
                  elevation={0}
                  selectedAccountLogin={selectedAccountLogin}
                  onSelectDate={handleSelectDate}
                  onMonthYearChange={handleMonthYearChange}
                  onResetPagination={resetPagination}
                  currentMonth={currentMonth}
                  currentYear={currentYear}
                />
                <Summary
                  selectedAccountLogin={selectedAccountLogin}
                  selectedDate={selectedDate}
                  onTradeHistoryFetched={setTradeHistory}
                  tableRef={tableRef}
                  inAccordion={false}
                />
              </Stack>

              <div ref={tableRef}>
                <TradingHistory
                  elevation={0}
                  selectedAccountLogin={selectedAccountLogin}
                  selectedDate={selectedDate}
                  tradeHistory={tradeHistory}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            // Mise en page "mobile/tablette"
            <Stack gap={2}>
              <Stack direction="row" alignItems="center" mt={6} gap={1}>
                <RadarIcon />
                <Typography variant="h5" component="h2" alignItems="center">
                  {t('TITLES.ACCOUNTS')}
                </Typography>
              </Stack>

              {loading ? (
                <ChallengeSkeleton />
              ) : (
                <AccordionCard
                  onSelect={handleSelectAccount}
                  onResetDate={resetSelectedDate}
                  challenges={challengeAccounts}
                  isFunded={false}
                >
                  {/* Contenu "déplié" de l'accordion (dashboard mobile) */}
                  <Stack gap={2}>
                    <Stack
                      direction={isBetweenXSAndLG ? 'column' : 'row'}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Chart elevation={1} selectedAccountLogin={selectedAccountLogin} />
                      <Rules elevation={1} selectedAccountLogin={selectedAccountLogin} />
                    </Stack>
                    <StatsCard elevation={1} />
                  </Stack>

                  <Stack mt={4}>
                    <Calendar
                      elevation={1}
                      selectedAccountLogin={selectedAccountLogin}
                      onSelectDate={handleSelectDate}
                      onMonthYearChange={handleMonthYearChange}
                      onResetPagination={resetPagination}
                      currentMonth={currentMonth}
                      currentYear={currentYear}
                    />
                    <Summary
                      selectedAccountLogin={selectedAccountLogin}
                      selectedDate={selectedDate}
                      onTradeHistoryFetched={setTradeHistory}
                      tableRef={tableRef}
                      inAccordion={true}
                    />
                  </Stack>

                  <div ref={tableRef}>
                    <TradingHistory
                      elevation={1}
                      selectedAccountLogin={selectedAccountLogin}
                      selectedDate={selectedDate}
                      tradeHistory={tradeHistory}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </AccordionCard>
              )}
            </Stack>
          )}
        </AccountBaseDataProvider>
      )}
    </>
  );
};

export default ClientArea;
